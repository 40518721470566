.sign-up-form-container {
  display: flex;
  flex-direction: column;
  width: 450px;
  h2 {
    margin-top: 10px 0;
  }

  .buttons-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}